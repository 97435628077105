import { ActionContext } from "vuex";
// import { api } from '@/services/api'

export default {
  toggleContraste(store: ActionContext<any, string>): void {
    store.commit("setIsContraste", !store.state.isContraste);
    store.dispatch("loadState");
  },
  loadState(store: ActionContext<any, string>): void {
    console.log("loadState", store.state.isContraste);

    if (store.state.isContraste) {
      document.documentElement.style.setProperty("--greyColorBg", "#000");
      document.documentElement.style.setProperty("--portalNav", "#000");
    } else {
      document.documentElement.style.setProperty("--greyColorBg", "#ececec");
      document.documentElement.style.setProperty("--portalNav", "#1c8047");
    }
  },
  // ============ fetch ===============
  // async fetchGroups(store: ActionContext<GroupState, string>, paramQuery: string): Promise<void> {
  // 	store.commit('setLoader', true)
  // 	store.commit('setGroups', [])
  // 	store.commit('setError', false)
  // 	await api.get(`/group${paramQuery}`).then(
  // 		response => {
  // 			if(response.data.list){
  // 				store.commit('setGroups', response.data)
  // 			}else{
  // 				store.commit('setError', 5000)
  // 			}
  // 		},
  // 		error => {
  // 			store.commit('setError', error)
  // 		}
  // 	)
  // 	store.commit('setLoader', false)
  // },
};
