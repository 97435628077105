// import { User } from '@/molds/User'

export default {
  isContraste(state: any): boolean {
    return state.isContraste;
  },
  // groups(state: GroupState): Group[] {
  // 	return state.groups
  // },
};
