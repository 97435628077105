// import { User } from '@/molds/User'

export default {
  setIsContraste(state: any, value: boolean): void {
    state.isContraste = value;
  },
  // setGroup(state: GroupState, group: Group): void {
  // 	state.group = group
  // },
  // setGroups(state: GroupState, groups: Group[]): void {
  // 	state.groups = groups
  // },
};
