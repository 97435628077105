
// true = production | false = develop
export const SYSTEM_PROD_MODE = true

export const VERSION = 'Versão Final-2.2.1'

export const portalName = "Prefeitura Municipal de XXXX...."
export const portalSite = "www.xxxxxx.es.gov.br"

export const urlBase = {
	DEV:'https://aps.laranjadaterra.es.gov.br/api/core',
	PROD:'https://aps.laranjadaterra.es.gov.br/api/core',
}

export const urlApiTransparencia = {
	DEV:'https://apitransparencia2.laranjadaterra.es.gov.br',
	PROD:'https://apitransparencia2.laranjadaterra.es.gov.br',
}

export default {
	SYSTEM_PROD_MODE,
	VERSION,
	portalName,
	portalSite,
	urlBase,
	urlApiTransparencia
}