import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import screen from "./modules/screen";
import acessibilidade from "./acessibilidade";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    screen,
    acessibilidade,
  },
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  plugins: [vuexLocal.plugin],
});
