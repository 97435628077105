import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/home.vue"),
  },
  {
    path: "/noticias",
    name: "Noticias",
    component: () => import("@/views/noticias/noticias.vue"),
  },
  {
    path: "/boletins",
    name: "Boletins",
    component: () => import("@/views/boletins/boletins.vue"),
  },
  {
    path: "/busca",
    name: "busca",
    component: () => import("@/views/busca.vue"),
  },
  {
    path: "/transparencia/licitacoes",
    name: "Transparencia-Licitacoes",
    component: () => import("@/views/transparencia/licitacoes/licitacoes.vue"),
  },
  {
    path: "/transparencia/licitacoes/:id",
    name: "Transparencia-Licitacoes-id",
    component: () =>
      import("@/views/transparencia/idTransparencia/idTransparencia.vue"),
  },
  {
    path: "/todos-documentos",
    name: "Todos-Documentos",
    component: () => import("@/views/todosDocumentos/todosDocumentos.vue"),
  },
  {
    path: "/todos-documentos/:id",
    name: "Todos-Documentos-ByID",
    component: () => import("@/views/todosDocumentos/byId/byId.vue"),
  },
  {
    path: "/mural-eletronico",
    name: "MuralEletronico",
    component: () => import("@/views/mural-eletronico/mural-eletronico.vue"),
  },
  {
    path: "/documento/:id",
    name: "Documento-ByID",
    component: () => import("@/views/documento/documento.vue"),
  },
  {
    path: "/normativas",
    name: "Normativas",
    component: () => import("@/views/normativas/normativas.vue"),
  },
  {
    path: "/normativas/:idTipo",
    name: "Normativas-id",
    component: () => import("@/views/normativas/idNormativa/idNormativa.vue"),
  },
  // load content wordpress
  {
    path: "/pagina/:slug",
    name: "Pagina",
    meta: { typePage: "pagina" },
    component: () => import("@/views/pagina/pagina.vue"),
  },
  {
    path: "/noticia/:slug",
    name: "Noticia",
    meta: { typePage: "noticia" },
    component: () => import("@/views/pagina/pagina.vue"),
  },
  {
    path: "/boletim/:slug",
    name: "Boletim",
    meta: { typePage: "boletim" },
    component: () => import("@/views/pagina/pagina.vue"),
  },
  {
    path: "/pagina-nao-encontrada",
    name: "NotFound",
    component: () => import("@/views/notFound/notFound.vue"),
  },
  {
    path: "*",
    redirect: "/pagina-nao-encontrada",
  },
];

export default routes;
